body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cardDomanda {
  box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(37 37 37 / 49%) !important;
  cursor: pointer;
  transition: 0.2s;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 0px !important;
  padding-right: 0px !important
}

.cardDomanda.showAnswerHoverEffect:hover {
  /* background-color: #1a1a1a !important;
  color: white !important;
  background-color: #b8b8b8 !important; */
  background-color: #717171 !important;
  color:white!important;
}

.goToTopButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
}


.pausedBackground {
  background: #000000ad;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.blurredBackground {
  background: rgba(255, 255, 255, 0.24);
  border-radius: 16px;
  backdrop-filter: blur(12.2px);
  -webkit-backdrop-filter: blur(12.2px);
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
}

.volumeCard {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 300px;
  color: white;
  background-color: rgba(0, 0, 0, 0.65);
  position: relative;
  overflow: hidden;
  height: 100%;
}

.backgroundVolumeCard {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10px;
  filter: blur(3px);
  background-size: cover;
  box-shadow: inset 0 0 0 3000px rgba(150, 150, 150, 0.192);
}

.volumeSelezionatoCard {
  display: flex;
  justify-content: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.65);
  position: relative;
  overflow: clip;
  height: 100%;
}

.backgroundVolumeSelezionatoCard {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0%;
  filter: blur(10px);
  background-size: cover;
  box-shadow: inset 0 0 0 3000px rgba(150, 150, 150, 0.192);
  transform: scale(120%);
}


.whiteButton {
  color: black;
  background-color: white;
  transition: 0.3s;
}

.whiteButton:hover {
  background-color: rgb(202, 202, 202);
}

.imageClass {
  /* max-width: 100%;
  max-height: 100px;
  padding: 5px;
  height: 50px; */
  height: 60px;
}

.answerImage {
  height: 60px;
}

#divDomanda img {
  max-width: 50%;
  max-height: 50px;
  padding: 5px;
}

.questionImage {
  width: 60%;
  height: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  list-style: none;

}

.pagination .buttonPagination {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  width: 52px;
  cursor: pointer;
}

.pagination li {
  display: inline-block;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  width: 52px;
  cursor: pointer;
}

.active {
  content: "";
  display: block;
  border-bottom: 0.3em solid #196BB4;

}

.checked {
  display: inline-block;
  color: rgba(0, 0, 0, 1) !important;
  width: 52px;
  cursor: pointer;
}

.checked-active {
  display: block;
  width: 52px;
  cursor: pointer;
  content: "";
  border-bottom: 0.3em solid #196BB4;
}

.pagination .buttonPaginationDisabled {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.25);
  width: 52px;
  cursor: no-drop;
}

.copertina-volume-dialog {
  -webkit-box-shadow: 0px 7px 16px 2px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 7px 16px 2px rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  max-width: 45%;
}

.cookies-eu-banner {
  -webkit-box-shadow: 0px 7px 16px 2px rgba(0, 0, 0, 0.38);
  box-shadow: 0px 7px 16px 2px rgba(0, 0, 0, 0.38);
  background: white;
  color: black;
  font-size: 13px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}
.backdrop-cookies {
  height: 100%;
  background-color: #000000a3;
  z-index: 9998;
  position: absolute;
  width: 100%;
  top: 0;
}
table {
  border-collapse: collapse;
}

td {
  text-align: center;
  padding: 10px;
}

.zoomImage {
  height: 100%;
  position: relative;
  cursor: zoom-in;
}

.titleContainer {
  border: 1px solid #ffffff2e;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  overflow-x: auto;
}


button {
  box-shadow: none !important;
  text-transform: none !important;
}
